import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["item", "list"]

  connect() {
    this.sortable = Sortable.create(this.listTarget, {
      handle: ".sortable-item",
      animation: 150
    })
  }

  save(event) {
    event.preventDefault()

    const order = this.sortable.toArray()
    const characterId = this.listTarget.dataset.characterId
    const csObject = this.listTarget.dataset.csObject
    const saveButton = event.target
    const originalButtonText = saveButton.textContent

    // Change the button text and disable it
    saveButton.disabled = true
    saveButton.textContent = 'Saving...'
    saveButton.classList.add('opacity-50', 'cursor-not-allowed')

    fetch(`/account/cypher/characters/${characterId}/set_sort_order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ order: order, cs_object: csObject })
    }).then(response => {
      if (response.ok) {
        saveButton.textContent = 'Saved!'
        window.location.href = `/account/cypher/characters/${characterId}`;
      } else {
        saveButton.textContent = 'Save Failed'
        console.error('Failed to save order.')
      }
    }).catch(error => {
      saveButton.textContent = 'Save Failed'
      console.error('Failed to save order:', error)
    }).finally(() => {
      setTimeout(() => {
        saveButton.disabled = false
        saveButton.textContent = originalButtonText
        saveButton.classList.remove('opacity-50', 'cursor-not-allowed')
      }, 2000)
    })
  }
}
