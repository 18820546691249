$(document).on("turbo:load", function () {

  // wire up a confirmation dialog for the campaign selector, if it exists
  const $selectField = $('#cypher_character_team_id');
  if ($selectField.length) {
    let initialIndex = $selectField.prop('selectedIndex');

    $selectField.on('change', function (event) {
      const confirmed = confirm('Are you sure you want to move to the new Campaign?');

      if (!confirmed) {
        event.preventDefault();
        $selectField.prop('selectedIndex', initialIndex);
      } else {
        initialIndex = $selectField.prop('selectedIndex');
      }
    });
  }

  $(document).on("trix-file-accept", function (event) {
    event.preventDefault();
  });

  $("#random_portrait").click(function (e) {
    e.preventDefault(); // Prevents the default anchor behavior
    randomizeSelectValue("cypher_character_portrait_age");
    randomizeSelectValue("cypher_character_portrait_build");
    randomizeSelectValue("cypher_character_portrait_skin_tone");
    randomizeSelectValue("cypher_character_portrait_hair_color");
    randomizeSelectValue("cypher_character_portrait_hair_style");
    randomizeSelectValue("cypher_character_portrait_eye_color");
    $("#cypher_character_portrait_clothing_style").val("").trigger("change");
  });

  $("#roll_d6").on("click", function () {
    roll_dice(6);
  });

  $("#roll_d20").on("click", function () {
    roll_dice(20);
  });

  $("#roll_d100").on("click", function () {
    roll_dice(100);
  });

  function roll_dice(sides) {
    const result = Math.floor(Math.random() * sides) + 1;
    console.log(`You rolled an unadjusted ${result} on a d${sides} on ${new Date().toLocaleString()}`)
    alert(`You rolled an unadjusted ${result} on a d${sides}`);
  }

  $("[data-toggle-target]").on("click", function () {
    const targetValue = $(this).data("toggle-target");

    // Toggle content
    const content = $(`[data-toggle-content="${targetValue}"]`);
    content.toggle();

    let isMultiple = false;
    const elements = $(`[data-toggle-multiple-content="${targetValue}"]`);
    const firstElementIsHidden = elements.first().is(":hidden");
    elements.each(function () {
      if (firstElementIsHidden) {
        $(this).show();
      } else {
        $(this).hide();
      }
      isMultiple = true;
    });

    const indicator = $(`[data-toggle-indicator="${targetValue}"]`);
    if (isMultiple || indicator.length > 0) {
      // Check if the indicator exists
      if (content.is(":visible") || (isMultiple && firstElementIsHidden)) {
        indicator.html("&#9661;"); // Content is now visible, so show the "open" indicator
      } else {
        indicator.html("&#9658;"); // Content is now hidden, so show the "closed" indicator
      }
    }
  });

  $("#damage_track_hale").on("change", function (e) {
    update_state_change("hale");
    update_damage_track_help();
  });

  $("#damage_track_impaired").on("change", function (e) {
    update_state_change("impaired");
    update_damage_track_help();
  });

  $("#damage_track_debilitated").on("change", function (e) {
    update_state_change("debilitated");
    update_damage_track_help();
  });

  $("#recovery_rested").on("change", function (e) {
    update_state_change("recovery_rested");
  });

  $("#recovery_one_action").on("change", function (e) {
    update_state_change("recovery_one_action");
  });

  $("#recovery_ten_mins").on("change", function (e) {
    update_state_change("recovery_ten_mins");
  });

  $("#recovery_one_hour").on("change", function (e) {
    update_state_change("recovery_one_hour");
  });

  $("#recovery_ten_hours").on("change", function (e) {
    update_state_change("recovery_ten_hours");
  });

  $("#might_up").on("click", function (e) {
    modify_displayed_value("#character_might", 1);
    autosave();
  });

  $("#might_down").on("click", function (e) {
    modify_displayed_value("#character_might", -1);
    autosave();
  });

  $("#luck_up").on("click", function (e) {
    modify_displayed_value("#character_luck", 1);
    autosave();
  });

  $("#luck_down").on("click", function (e) {
    modify_displayed_value("#character_luck", -1);
    autosave();
  });

  $("#speed_up").on("click", function (e) {
    modify_displayed_value("#character_speed", 1);
    autosave();
  });

  $("#speed_down").on("click", function (e) {
    modify_displayed_value("#character_speed", -1);
    autosave();
  });

  $("#intellect_up").on("click", function (e) {
    modify_displayed_value("#character_intellect", 1);
    autosave();
  });

  $("#intellect_down").on("click", function (e) {
    modify_displayed_value("#character_intellect", -1);
    autosave();
  });

  $("#experience_points_up").on("click", function (e) {
    modify_displayed_value("#character_experience_points", 1);
    autosave();
  });

  $("#experience_points_down").on("click", function (e) {
    modify_displayed_value("#character_experience_points", -1);
    autosave();
  });

  function modify_displayed_value(div_id, int_value) {
    current = $(div_id).text();
    val = parseInt(current);
    val += int_value;
    if (val < 0) {
      return;
    }
    if (val > 50) {
      return;
    }
    $(div_id).text(val);
    if (div_id == "#character_might" || div_id == "#character_speed" || div_id == "#character_intellect") {
      update_damage_track();
    }
  }

  function update_damage_track() {
    might = parseInt($("#character_might").text()) <= 0 ? 1 : 0;
    speed = parseInt($("#character_speed").text()) <= 0 ? 1 : 0;
    intellect = parseInt($("#character_intellect").text()) <= 0 ? 1 : 0;

    total_zero = might + speed + intellect;

    current_state = $("input[name=damage_track]:checked").prop("id");

    if (total_zero == 0) {
      if (current_state != "damage_track_hale") {
        $("#damage_track_hale").prop("checked", true);
        update_damage_track_help();
        update_state_change("hale");
      }
      return;
    }

    if (total_zero == 1) {
      if (current_state != "damage_track_impaired") {
        $("#damage_track_impaired").prop("checked", true);
        update_damage_track_help();
        update_state_change("impaired");
      }
      return;
    }

    if (current_state == "damage_track_debilitated") {
      return;
    }
    $("#damage_track_debilitated").prop("checked", true);
    update_damage_track_help();
    update_state_change("debilitated");
  }

  function update_damage_track_help() {
    current_state = $("input[name=damage_track]:checked").prop("id");
    speed = parseInt($("#character_speed").text());

    if (current_state == "damage_track_hale") {
      $("#impaired_help").hide();
      $("#debilitated_speed_positive").hide();
      $("#debilitated_speed_zero").hide();
    }

    if (current_state == "damage_track_impaired") {
      $("#impaired_help").show();
      $("#debilitated_speed_positive").hide();
      $("#debilitated_speed_zero").hide();
    }

    if (current_state == "damage_track_debilitated") {
      $("#impaired_help").show();
      if (speed <= 0) {
        $("#debilitated_speed_zero").show();
      } else {
        $("#debilitated_speed_positive").show();
      }
    }
  }

  var autosave_timer = null;

  function update_state_change(attribute) {
    clearTimeout(autosave_timer);
    $.ajax({
      type: "POST",
      url: window.location.href + "/autosave",
      data: {
        might: $("#character_might").text(),
        speed: $("#character_speed").text(),
        intellect: $("#character_intellect").text(),
        luck: $("#character_luck").text(),
        experience_points: $("#character_experience_points").text(),
        money: $("#character_money").text(),
        attribute_state_change: attribute,
        armor: $("#character_armor").text(),
      },
      accept: {
        javascript: "application/javascript",
      },
      dataType: "json",
      success: function (data) {
        // all good
      },
    });
  }

  function autosave() {
    clearTimeout(autosave_timer);
    autosave_timer = setTimeout(function () {
      console.log("autosaving");
      $.ajax({
        type: "POST",
        url: window.location.href + "/autosave",
        data: {
          might: $("#character_might").text(),
          speed: $("#character_speed").text(),
          intellect: $("#character_intellect").text(),
          luck: $("#character_luck").text(),
          experience_points: $("#character_experience_points").text(),
          money: $("#character_money").text(),
          armor: $("#character_armor").text(),
        },
        accept: {
          javascript: "application/javascript",
        },
        dataType: "json",
        success: function (data) {
          // all good
        },
      });
    }, 2500);
  }

  function randomizeSelectValue(selectId) {
    const selectElement = $("#" + selectId);
    if (selectElement.length === 0) return; // Exit if select element not found

    const options = selectElement.find("option");
    if (options.length === 0) return; // Exit if there are no options

    // Generate a random index (excluding the first option if it's a placeholder)
    const randomIndex = Math.floor(Math.random() * options.length);

    // Set the value to the value of the option at the random index
    selectElement.val(options.eq(randomIndex).val()).trigger("change");
  }
});

// defined on the specific character page
// let selectedImageId = null;
// const stockPortraitImages = <%= raw images.to_json %>;
// const stockPortraitUpdateUrl = '<%=account_cypher_character_add_stock_portrait_path(@character)%>';

if (typeof placeholderPortraitImgSrc === "undefined") {
  var placeholderPortraitImgSrc = null;
}
if (typeof openPortraitPopupLoaded === "undefined") {
  var openPortraitPopupLoaded = false;
}

$(document).on("turbo:load", function () {
  openPortraitPopupLoaded = false;
});

window.openPortraitPopup = function openPortraitPopup() {
  document.getElementById("image-popup").classList.remove("hidden");
  renderPortraitImages();
};

window.closePortraitPopup = function closePortraitPopup() {
  document.getElementById("image-popup").classList.add("hidden");
  selectedImageId = null;
  document.querySelectorAll(".image-item").forEach((item) => {
    item.classList.remove("border-mcg-red");
  });
};

window.renderPortraitImages = function renderPortraitImages() {
  if (openPortraitPopupLoaded) {
    return;
  }
  openPortraitPopupLoaded = true;

  const imageGrid = document.getElementById("image-grid");
  const categoryList = document.getElementById("category-list");
  imageGrid.innerHTML = ""; // Clear any existing images
  categoryList.innerHTML = ""; // Clear any existing links
  const categories = Object.keys(stockPortraitImages);

  categories.forEach((category, index) => {
    // Create and append the category banner
    const categoryBanner = document.createElement("div");
    categoryBanner.classList.add(
      "category-banner",
      "col-span-full",
      "flex",
      "items-center",
      "justify-center",
      "font-semibold",
      "font-sans-header",
      "text-xl",
      "m-4"
    );
    categoryBanner.id =
      "category-" + category.toLowerCase().replace(/[^a-z0-9]/g, "-");
    categoryBanner.innerText =
      category.charAt(0).toUpperCase() + category.slice(1);
    imageGrid.appendChild(categoryBanner);

    // Create and append the anchor tag for the category
    const categoryLink = document.createElement("a");
    categoryLink.href = `#${categoryBanner.id}`;
    categoryLink.innerText =
      category.charAt(0).toUpperCase() + category.slice(1);
    categoryLink.setAttribute("data-turbo", "false");
    categoryLink.classList.add("py-2", "text-2xs", "font-semibold");
    categoryList.appendChild(categoryLink);

    // Add a middle dot separator after each category link except the last one
    if (index < categories.length - 1) {
      const middot = document.createElement("span");
      middot.innerHTML = "&middot;";
      middot.classList.add("mx-1");
      categoryList.appendChild(middot);
    }

    // Loop over the images in the category
    stockPortraitImages[category].forEach((image) => {
      const imageItem = document.createElement("div");
      imageItem.classList.add("image-item", "border-4", "cursor-pointer");
      imageItem.setAttribute("data-id", category + "::" + image.id);
      imageItem.onclick = () => selectPortraitImage(category + "::" + image.id);

      const img = document.createElement("img");
      img.src = image.url;
      img.alt = "Portrait";
      img.classList.add("w-full", "h-32", "object-cover");
      img.loading = "lazy";

      imageItem.appendChild(img);
      imageGrid.appendChild(imageItem);
    });
  });
};

window.selectPortraitImage = function selectPortraitImage(id) {
  document.querySelectorAll(".image-item").forEach((item) => {
    item.classList.remove("border-mcg-red");
  });

  const selectedItem = document.querySelector(`[data-id="${id}"]`);
  selectedItem.classList.add("border-mcg-red");
  var parts = id.split("::"); // id = 'category::image_id'
  selectedImageId = parts[1];
};

window.applyPortraitSelection = function applyPortraitSelection() {
  inputElement = document.getElementById("character_portrait_id");
  if (inputElement) {
    inputElement.value = selectedImageId;

    let found = false;
    const categories = Object.keys(stockPortraitImages);
    for (let category of categories) {
      if (found) break;
      for (let image of stockPortraitImages[category]) {
        if (image.id == selectedImageId) {
          let portraitImage = document.getElementById(
            "character_portrait_image"
          );
          if (placeholderPortraitImgSrc == null) {
            placeholderPortraitImgSrc = portraitImage.src;
          }
          portraitImage.src = image.url;
          found = true;
          break;
        }
      }
    }

    closePortraitPopup();
    return;
  }

  if (selectedImageId) {
    fetch(stockPortraitUpdateUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ image_id: selectedImageId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          window.location.reload();
        } else {
          alert("An error occurred.");
        }
      });
  } else {
    alert("Please select an image first.");
  }
};

window.deletePortraitSelection = function deletePortraitSelection() {
  var inputElement = document.getElementById("character_portrait_id");
  if (inputElement) {
    selectedImageId = null;
    inputElement.value = "blank";
    if (placeholderPortraitImgSrc != null) {
      document.getElementById("character_portrait_image").src =
        placeholderPortraitImgSrc;
    }
    closePortraitPopup();
    return;
  }

  fetch(stockPortraitUpdateUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    // Cypher::CharacterImageStock::BLANK_STOCK_IMAGE_ID
    body: JSON.stringify({ image_id: "blank" }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        window.location.reload();
      } else {
        alert("An error occurred.");
      }
    });
};
